import React from 'react';
import { Box, Flex, Image, Text, Heading } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';

export default function About({data, onConnect, address, onMint}) {
  return (
    <Box
      backgroundImage={`${process.env.PUBLIC_URL}/about.png`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundColor={'blue'}
      backgroundSize={'cover'}


    >
      <Flex
        maxW="1350px"
        width={'100%'}
        mx="auto"
        h={{ base: '100%', md: '100vh' }}
        flexDirection="column"
        position="relative"
        id="about"
        alignItems="center"
        justifyContent="center"
        // py="60px"
        
      >
        <Flex
          mt="40px"
          w="100%"
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Fade left>
            <Image
              ml={{ base: 0, md: '10px' }}
              maxW={{ base: '100%', lg: '550px' }}
              src={`${process.env.PUBLIC_URL}/about2.png`}
            />
          </Fade>
          <Box
            mr="20px"
            textAlign={{ base: 'left', md: 'justify' }}
            color="white"
            maxW="550px"
            px={{ base: '20px', md: 0 }}
          >
            <Fade right>
              <Heading color="about" fontSize="70px">
                About Us
              </Heading>
            </Fade>
            <Fade right delay={500}>
              <Heading mb="30px" fontSize="50px">
                Bored Ape Teen Club
              </Heading>
            </Fade>
            <Fade right delay={1000}>
              <Text mb="15px" fontSize="18px">
                We know, the world out there is getting pretty crazy and staying
                indoors is driving everyone BORED, Ape Teens especially.
              </Text>
              <Text mb="15px" fontSize="18px">
                The typical Bored Ape Teen is one who is no longer satisfied to be
                defined and treated as a Little Ape Kid anymore; the
                Bored Ape Teen wants FREEDOM and INDEPENDENCE!
              </Text>
              <Text mb="15px" fontSize="18px">
                But how does the Bored Ape Teen navigate the NFT universe that
                offers so much, yet tells so little?
              </Text>
              <Text mb="15px" fontSize="18px">
                Enter the Bored Ape Teen Club!
              </Text>
            </Fade>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );

 
 
}

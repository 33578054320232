import React, { useReducer, useEffect, useCallback } from 'react';
import {
  ChakraProvider,
  Box,
  theme,
  extendTheme,
} from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import Web3 from 'web3';

import Fonts from './Fonts';
import { Home, Gallery, About, Features, Roadmap, Team, Mint } from './pages';
import { Footer } from './components';

import SmartContractABI from './contracts/ABI.json';

const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';

const {
  REACT_APP_CONTRACT_ADDRESS,
  REACT_APP_API_URL,
  REACT_APP_NETWORK_ID,
  REACT_APP_NETWORK,
} = process.env;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://cdn.cdnlogo.com/logos/c/12/coinbase.png',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'BitBullz Club', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: REACT_APP_NETWORK_ID,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

let web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true,
  providerOptions, // required
});

const extendedTheme = extendTheme({
  ...theme,
  fonts: {
    heading: 'Red Rose',
    body: 'Red Rose',
  },
  colors: {
    ...theme.colors,
    bg: '#f5f5f1',
    bgLight: '#efeee8',
    bgDark: '#e8e7df',
    lightBlack: '#181817',
    lightOrange: '#FFAE01',
    box:'#F2EAC6A3',
    about:'#805700',
    blue:'#de1cf6',
  },
});

function App() {
  return (
    <ChakraProvider theme={extendedTheme}>
      <Fonts />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="mint" element={<Mint />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ChakraProvider>
  );
}

function Main() {
   const wallet = useSelector(state => state.wallet);
   const dispatch = useDispatch();
   const { address, provider, chainId } = wallet;
  
  const connect = useCallback(async function () {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    console.log('Web3 instance is', web3);
    const address = await web3.eth.getAccounts();
    const network = await web3.eth.getChainId();
    const SmartContractObj = new web3.eth.Contract(
      SmartContractABI,
      REACT_APP_CONTRACT_ADDRESS
    );
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      web3Provider: web3,
      provider,
      address: address[0],
      chainId: network,
      smartContract: SmartContractObj,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect();
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      });
    },
    [provider]
  );

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (chainId && chainId !== Number(REACT_APP_NETWORK_ID)) {
      toast.error(`Change the network to ${REACT_APP_NETWORK}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  }, [chainId]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = accounts => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = _hexChainId => {
        window.location.reload();
      };

      const handleDisconnect = error => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  return (
    <Box minH="100vh" bg="black">
      <Box>
        <Home onConnect={connect} address={address} />
        <About />
        <Features />

        <Gallery />
        <Roadmap />

        <Team />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;

import React from 'react';
import { Box, Flex, Image, Text, Heading, Link } from '@chakra-ui/react';
import Countdown from "react-countdown";
import {
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';
import { Link as RouteLink } from 'react-router-dom';


const Completionist = () => <Link href="https://"> <Text color="white" fontSize="25px">Mint Now</Text></Link>;
var today = Date.now();
var event = Date.UTC(2022,1, 2, 19,0,0,0);



const renderer = ({ days,hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return (
      <RouteLink to="/mint">
        <Text fontSize="19px">Mint Now</Text>
      </RouteLink>
    );
  } else {
    // Render a countdown
    return (
      <RouteLink to="/mint">
        <Text fontSize="19px">
          Mint Now
        </Text>
      </RouteLink>
    );
  }
};
export default function Features() {
  return (
    <Box
      backgroundImage={`${process.env.PUBLIC_URL}/about3.png`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      mt={{ base: '0px', lg: '0px' }}
      backgroundSize={'cover'}
    >
      <Flex
        maxW="1300px"
        mx="auto"
        h={{ base: '100%', md: '100vh' }}
        flexDirection="column"
        position="relative"
        alignItems="center"
        id="feature"
        justifyContent="center"
        // py="10px"
      >
        <Flex
          mt="80px"
          mb="20px"
          maxW="1320px"
          mx="auto"
          w="100%"
          alignItems="center"
          justifyContent="space-around"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box
            textAlign={{ base: 'left', md: 'justify' }}
            color="white"
            maxW="550px"
            px={{ base: '20px', md: 0 }}
          >
            <Fade left delay={200}>
              <Heading color="about" fontSize="70px">
                Our Collection
              </Heading>
            </Fade>
            <Fade left delay={500}>
              <Heading mb="10px" fontSize="50px">
                See It To Believe It
              </Heading>
            </Fade>
            <Fade left delay={700}>
              <UnorderedList maxW="400px">
                <ListItem mb="3px" fontSize="18px">
                  NFT collection of <span className="color">3000</span> uniquely
                  designed Bored Ape Teen
                </ListItem>
                <ListItem mb="3px" fontSize="18px">
                  200+ traits
                </ListItem>
                <ListItem mb="3px" fontSize="18px">
                Mint Price: 0.09 ETH
                </ListItem>
              </UnorderedList>
            </Fade>
            <Fade right delay={1000}>
              <Flex
                // as="button"
                bg="lightOrange"
                w="330px"
                textAlign="center"
                h="57px"
                borderRadius="50px"
                mt="20px"
                justifyContent="center"
                alignItems="center"
              >
                {/* {console.log('1=> ' ,Date.UTC(2022,1, 2, 19,0,0,0))}
                {console.log('2=> ' , today)}
                {console.log('3=> ' , event - today)} */}

                <Countdown
                  fontSize="25px"
                  date={Date.now() + event - today}
                  renderer={renderer}
                />
              </Flex>
            </Fade>
          </Box>
          <Fade right>
            <Image
              maxW={{ base: '100%', lg: '600px' }}
              mt={{ base: '30px', md: 0 }}
              src={`${process.env.PUBLIC_URL}/about4.png`}
            />
          </Fade>
        </Flex>
      </Flex>
    </Box>
  );
}

import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';

import { SiDiscord } from 'react-icons/si';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import { Link as RouteLink } from 'react-router-dom';

import Audit from '../assets/audit.pdf';

export default function Footer() {
  return (
    <>
      <Flex
        backgroundImage={`${process.env.PUBLIC_URL}/footerbg.png`}
        backgroundSize="100%"
        backgroundRepeat="no-repeat"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        px="20px"
      >
        <Flex
          mt="40px"
          mb="100px"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Zoom>
            <Link
              w="359px"
              h="59px"
              display="flex"
              bg="blue"
              borderRadius="100px"
              color="white"
              alignItems="center"
              justifyContent="center"
              href="https://discord.gg/BQVfzsAKHJ"
              isExternal
              mb={{ base: '20px', md: 0 }}
            >
              <SiDiscord size="25px" />
              <Text fontSize={{ base: '20px', md: '30px' }} ml="20px">
                Join us on Discord
              </Text>
            </Link>
          </Zoom>
          <Zoom>
            <Link
              w={{ base: '100%', lg: '582px' }}
              h="57px"
              display="flex"
              bg="lightOrange"
              borderRadius="100px"
              color="white"
              alignItems="center"
              ml={{ base: 0, md: '10px' }}
              justifyContent="center"
              textAlign={{ base: 'center', md: 'left' }}
              href="#"
            >
              <RouteLink to="mint">
                <Text fontSize={{ base: '20px', md: '30px' }}>
                  Own a Bored Ape Teen{' '}
                </Text>
              </RouteLink>
            </Link>
          </Zoom>
        </Flex>
        <Image mt="100px" src={`${process.env.PUBLIC_URL}/footer.png`} />
      </Flex>
      <Slide bottom delay={500}>
        <Flex
          backgroundImage={`${process.env.PUBLIC_URL}/footer2.png`}
          // backgroundPosition="center"
          backgroundSize="cover"
          justifyContent="space-between"
          alignItems="center"
          h="128px"
          py="20px"
          px="20px"
        >
          <Flex
            maxW="1320px"
            mx="auto"
            w="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex justifyContent="center" alignItems="center">
              <Link
                borderWidth="1px"
                w="44px"
                h="44px"
                display="flex"
                borderColor="lightOrange"
                borderRadius="100px"
                color="lightOrange"
                alignItems="center"
                justifyContent="center"
                href="https://www.instagram.com/boredapeteen/"
                isExternal
              >
                <AiFillInstagram size="25px" />
              </Link>

              <Link
                borderWidth="1px"
                w="44px"
                ml="10px"
                h="44px"
                display="flex"
                borderColor="lightOrange"
                borderRadius="100px"
                color="lightOrange"
                alignItems="center"
                justifyContent="center"
                href="https://twitter.com/BoredApeTeen"
                isExternal
              >
                <AiOutlineTwitter size="25px" />
              </Link>
              <Link
                borderWidth="1px"
                w="44px"
                ml="10px"
                h="44px"
                display="flex"
                borderColor="lightOrange"
                borderRadius="100px"
                color="lightOrange"
                alignItems="center"
                justifyContent="center"
                href="https://opensea.io/collection/bored-ape-teen-club-official"
                isExternal
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/opensea.svg`}
                  size="25px"
                />
              </Link>
              <Link
                borderWidth="1px"
                w="44px"
                ml="10px"
                h="44px"
                display="flex"
                borderColor="lightOrange"
                borderRadius="100px"
                color="lightOrange"
                alignItems="center"
                justifyContent="center"
                href="https://looksrare.org/collections/0x6E188BcBFc30A461C83Edc70601cb088F560B730"
                isExternal
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/lookshare.png`}
                  size="10px"
                />
              </Link>
            </Flex>
            <Flex>
              <Link
                isExternal
                href="https://etherscan.io/address/0x6e188bcbfc30a461c83edc70601cb088f560b730"
                mr="15px"
              >
                <Text color="white" fontSize="18px">
                  smart contract
                </Text>
              </Link>
              <a target="_blank" href={Audit} rel="noopener noreferrer">
                <Text color="white" fontSize="18px">
                  smart contract audit
                </Text>
              </a>
            </Flex>
          </Flex>
        </Flex>
      </Slide>
    </>
  );
}

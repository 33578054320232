import { Box,Flex, Text, Heading,Image } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';

export default function Roadmap() {
  
 
  return (
    <Box
      backgroundImage={`${process.env.PUBLIC_URL}/roadmapbg.png`}
      backgroundPosition="center"
    >
      <Flex
        maxW="1320px"
        mx="auto"
        flexDirection="column"
        position="relative"
        alignItems="center"
        id="roadmap"
        justifyContent="center"
        py="10px"
      >
        <Flex
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          mb="60px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Fade left>
            <Box
              px={{ base: '20px', md: 0 }}
              ml={{ base: 0, lg: '20px' }}
              maxW="500px"
              color="white"
            >
              <Heading fontSize="70px" color="about">
                Roadmap{' '}
              </Heading>
              <Heading mb="40px" fontSize="50px">
                "Community First"
              </Heading>
              <Text textAlign="justify" letterSpacing="2px" fontSize="18px">
                We are here to GROW. Bored Ape Teens will take on the world with
                each brave new step into the Metaverse!
              </Text>
            </Box>
          </Fade>
          <Fade right>
            <Box
              mr={{ base: 0, md: '20px' }}
              color="white"
              textAlign="justify"
              letterSpacing="2px"
              fontSize="18px"
              maxW="650px"
              px={{ base: '20px', md: 0 }}
            >
              As part of our vision for our community, BATC NFTs will bridge the
              gap between the NFT space and the physical world. As our community
              grows and its reach extends, we will embark on collaborations with
              other like-minded Apes to brave new frontiers. You ask where can
              your Bored Ape Teen bring you? We say the Bored Ape Teen can go anywhere
              it dreams of.
            </Box>
          </Fade>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/0.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="202px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                "The Urban"
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                A club where the coolest and trendiest Bored Ape Teens want to
                hang; a club where you will hear the latest gossips surrounding
                the next big NFT collection; a club where your voice is heard; a
                club where community happens even before you have to drop a
                single cent.
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/20a.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="229px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                Get Creative
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                Let everyone who knows anything about NFTs know that you own a
                Bored Ape Teen; produce your own physical merchandise and have it
                delivered to your doorstep, design digital banners to flaunt on
                your accounts, you do you! Owning a Bored Ape Teen means you hold
                FULL commercial rights; we weren’t kidding when we said “the
                Bored Ape Teen can go anywhere it dreams of.”
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/40a.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="202px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                Time for a “Dance Party”
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                Things are getting really wild now, just as you begin to take in
                everything that is changing for you since owning a Bored Ape Teen,
                the most popular Ape Gang starts inviting you for their social
                events! We envision Ape communities coming together and partying
                the night away in Apes-clusive events all around the world.
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/60a.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="202px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                Coin or Token?
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                The currency of the future, Bored Ape Teens know that any
                community bananas enough dreams of having their own currency. In
                the BATC, we believe that no dream is too big, no Ape is too
                small. Any Bored Ape Teen that is crazy enough may apply to join
                the team in realizing this for our community
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/80a.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="337px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                CHRISTMAS PRESENTS
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                Say what?! First of all, congratulations on HODL-ing with that
                Ape grip of yours. Free personalized artwork by renowned digital
                art creators? Free add-on mintables to launch your Bored Ape Teen
                into the next stage of life? Free limited-edition owners-only
                game? In any case, remember that a Bored Ape Teen yearns to
                navigate the universe in search of the next grand banana.
                <br />
                The Christmas season is a time of the year when Family gather to
                exchange fond stories of yesteryears, where they reconnect on a
                level that can never be replicated unless you're in the warmth
                and security of HOME, where presents mean more than the monetary
                value tagged to it, where the focus is never on any one
                individual. This is when the Bored Ape Teen considers each other
                Family, and not just your run-of-the-mill NFT community.
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/100a.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="256px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                This. is. MASSIVE.
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                The BATC NFT collection will likely be a part of a larger Bored
                Ape movement by this stage, and the pain of climbing up the BATC
                Whitelists would be a thing of the past. You are now on multiple
                VIP lists that earns you exclusive invites into other
                Whitelists, simply because every other fledging project yearns
                for your support. Bored Ape Teen Gym? Bored Ape Teen Clubhouse? Few
                things can stop a Family that's as united as us by this time.
                And great job on making it this far, you’re a bona fide star!
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          mb="20px"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          px={{ base: '20px', md: 0 }}
        >
          <Image
            ml={{ base: 0, lg: '60px' }}
            mb={{ base: '20px', lg: 0 }}
            maxW="86px"
            h="86px"
            src={`${process.env.PUBLIC_URL}/xx.png`}
          />

          <Flex
            color="white"
            w={{ base: '100%', lg: '1038px' }}
            minH="337px"
            borderRadius="1px"
            mr={{ base: 0, lg: '15px' }}
            borderWidth="1px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderColor="lightOrange"
            p={{ base: '10px', md: 0 }}
          >
            <Box maxW="980px">
              <Heading mb="10px" fontSize="40px">
                The Metaverse
              </Heading>
              <Text textAlign="justify" letterSpacing="3px">
                The Metaverse is another X years older, and so are you. Who
                knows what your Bored Ape Teen could be by now? One thing is for
                sure, you won’t ever be in the FOMO gang. Metaverse, Web 3.0,
                DeFi, SciFi……we will be there.
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

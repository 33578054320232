import { Flex, Heading, Image, Text, Box } from '@chakra-ui/react';
export default function TeamCard({ image, name, text,position ="",style= "visible" }) {
  return (
    <Flex
      flexDirection="column"
      // justifyContent="center"
      alignItems="center"
      borderWidth="1px"
      mb="20px"
      // mr="10px"
      borderColor="about"
      w={{ base: '100%', md: '50%', lg: '30%' }}
      maxW="290px"
      visibility={style}
    >
      <Flex justifyContent="center" position="relative" w="250px">
        <Image
          src={`${process.env.PUBLIC_URL}/${image}`}
          maxW="270px"
          mt="7px"      
        />
      
      </Flex>
      <Heading fontSize="24px" mt="10px" color="white">
        {name}
      </Heading>
      {position && (
        <Text  maxW="250px" textAlign="center" mb="10px" fontSize="18px" color="white">
          {position}
        </Text>
      )}

      {text && (
        <Text textAlign="justify" maxW="250px" mb="10px" fontSize="18px" color="white">
          {text}
        </Text>
      )}
    </Flex>
  );
}

import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Box, Flex, Text, Heading, Image } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import axios from 'axios';
import { Link as RouteLink } from 'react-router-dom';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import WalletLink from 'walletlink';
import Web3 from 'web3';

import { getRandomNumberArrs } from '../utils';
import { shortenAddress } from '../candy-machine';
import SmartContractABI from '../contracts/ABI.json';

const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';

const {
  REACT_APP_CONTRACT_ADDRESS,
  REACT_APP_API_URL,
  REACT_APP_NETWORK_ID,
  REACT_APP_NETWORK,
} = process.env;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  'custom-walletlink': {
    display: {
      logo: 'https://cdn.cdnlogo.com/logos/c/12/coinbase.png',
      name: 'Coinbase',
      description: 'Connect to Coinbase Wallet (not Coinbase App)',
    },
    options: {
      appName: 'BitBullz Club', // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: REACT_APP_NETWORK_ID,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

let web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true,
  providerOptions, // required
});

export default function Mint() {
  const [whitelist, setwhitelist] = useState({});
  const [fetching, setFetching] = useState(false);
  const [count, setcount] = useState(1);
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const {
    address,
    smartContract,
    data,
    web3Provider,
    loading: isLoading,
    provider,
    chainId,
  } = wallet;

  const {
    totalSupply,
    maxSupply,
    cost,
    presaleActive,
    saleActive,
    maxMintAmount,
    maxMintHigerAmount,
    owner,
  } = data;

  const loading = fetching || isLoading;

  const connect = useCallback(async function () {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    console.log('Web3 instance is', web3);
    // const web3Provider = Web3EthContract.setProvider(provider);
    const address = await web3.eth.getAccounts();
    const network = await web3.eth.getChainId();
    const SmartContractObj = new web3.eth.Contract(
      SmartContractABI,
      REACT_APP_CONTRACT_ADDRESS
    );
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      web3Provider: web3,
      provider,
      address: address[0],
      chainId: network,
      smartContract: SmartContractObj,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect();
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      });
    },
    [provider]
  );

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (chainId && chainId !== Number(REACT_APP_NETWORK_ID)) {
      toast.error(`Change the network to ${REACT_APP_NETWORK}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  }, [chainId]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = accounts => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = _hexChainId => {
        window.location.reload();
      };

      const handleDisconnect = error => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const getData = async () => {
    if (!!smartContract) {
      const web3 = web3Provider || new Web3(provider);
      let totalSupply = await smartContract.methods.totalSupply().call();
      let cost = await smartContract.methods.cost().call();
      let maxSupply = await smartContract.methods.maxSupply().call();
      let presaleActive = await smartContract.methods.presaleActive().call();
      let saleActive = await smartContract.methods.saleActive().call();
      let maxMintAmount = await smartContract.methods
        .maxMintAmountPerTx()
        .call();
      let maxMintHigerAmount = await smartContract.methods
        .maxMintAmountPerTxForHigher()
        .call();
      let ownerAddr = await smartContract.methods.owner().call();
      const price = web3.utils.fromWei(cost, 'ether');
      console.log(price, totalSupply, maxSupply, maxMintAmount, ownerAddr);
      dispatch({
        type: 'SET_DATA',
        data: {
          totalSupply,
          maxSupply,
          maxMintAmount,
          cost: price,
          owner: ownerAddr,
          presaleActive,
          saleActive,
          maxMintHigerAmount,
        },
      });
    }
  };

  useEffect(() => {
    if (smartContract) {
      getData();
      forceUpdate();
    }
  }, [smartContract]);

  const checkIfHigherLevel = () => {
    if (Object.keys(whitelist).length > 0 && address) {
      return (
        whitelist[address.toLowerCase()] === Number(data.maxMintHigerAmount)
      );
    }

    return false;
  };

  const maxAmount = checkIfHigherLevel() ? maxMintHigerAmount : maxMintAmount;

  const getWhitelistedUsers = async () => {
    try {
      setFetching(true);
      const response = await axios.get(REACT_APP_API_URL);
      const data = response.data.data;
      setwhitelist(data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  };

  useEffect(() => {
    getWhitelistedUsers();
  }, []);

  const handleConnect = () => {
    dispatch(connect());
    getData();
  };

  const generateRandomIds = async count => {
    const randomArr = [];
    const mintCount = Array.from(Array(Number(count)).keys());
    console.log(data.maxSupply);
    for await (const num of mintCount) {
      const randonNum = await getRandomNumberArrs(
        randomArr,
        data.maxSupply,
        smartContract.methods.checkIfMinted
      );
      randomArr.push(randonNum);
    }
    console.log(randomArr);
    return randomArr;
  };

  const claimNFTs = async count => {
    if (count <= 0) {
      return;
    }

    if (!smartContract) {
      return;
    }

    const web3 = web3Provider || new Web3(provider);

    toast.info('Preparing your NFT...');
    const value = web3.utils.toWei((data.cost * count).toString(), 'ether');
    const ids = await generateRandomIds(count);
    smartContract.methods
      .mint(count, ids)
      .estimateGas({ from: address, value })
      .then(gas => {
        smartContract.methods
          .mint(count, ids)
          .send({
            gas,
            to: REACT_APP_CONTRACT_ADDRESS,
            from: address,
            value,
          })
          .once('error', err => {
            console.log(err);
            toast.error('It seems the transaction was cancelled.');
          })
          .then(receipt => {
            toast.success('Woohoo! NFT minted successfully!');
            getData();
          });
      })
      .catch(err => {
        if (err.message.includes('insufficient funds')) {
          toast.error('Insufficient Funds');
        } else {
          const formatedError = err.message.split('{')[0];
          if (formatedError.includes('execution reverted:')) {
            toast.error(formatedError.split('execution reverted:')[1]);
          } else toast.error(formatedError);
        }
      });
  };

  const whitelistClaimNFTs = async count => {
    if (count <= 0) {
      return;
    }

    if (!smartContract) {
      return;
    }

    const web3 = web3Provider || new Web3(provider);

    if (Object.keys(whitelist).length === 0) {
      toast.error('Something went wrong!');
      return;
    }

    toast.info('Preparing your NFT...');
    const value = web3.utils.toWei((data.cost * count).toString(), 'ether');
    const ids = await generateRandomIds(count);
    const addresses = Object.keys(whitelist);
    const leaves = addresses.map(addr => keccak256(addr.toLowerCase()));
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const allowance = whitelist[address.toLowerCase()] || 3;
    const currentHash = web3.utils.soliditySha3(address);
    const proof = tree.getHexProof(currentHash);
    console.log(allowance);
    smartContract.methods
      .whitelistMint(count, currentHash, proof, allowance, ids)
      .estimateGas({ from: address, value })
      .then(gas => {
        smartContract.methods
          .whitelistMint(count, currentHash, proof, allowance, ids)
          .send({
            gas,
            to: REACT_APP_CONTRACT_ADDRESS,
            from: address,
            value,
          })
          .once('error', err => {
            console.log(err);
            toast.error('It seems the transaction was cancelled.');
          })
          .then(receipt => {
            toast.success('Woohoo! NFT minted successfully!');
            getData();
          });
      })
      .catch(err => {
        if (err.message.includes('insufficient funds')) {
          toast.error('Insufficient Funds');
        } else if (
          err.message.includes('Not a valid leaf in the Merkle tree')
        ) {
          toast.error('Not a whitelist user');
        } else {
          const formatedError = err.message.split('{')[0];
          if (formatedError.includes('execution reverted:')) {
            toast.error(formatedError.split('execution reverted:')[1]);
          } else {
            toast.error(formatedError);
          }
        }
      });
  };

  const isOwner = () => {
    if (owner && address) {
      console.log(owner.toLowerCase() === address.toLowerCase());
      return owner.toLowerCase() === address.toLowerCase();
    }

    return false;
  };

  const onWithdraw = () => {
    if (!smartContract) {
      return;
    }
    if (!isOwner()) {
      return;
    }
    smartContract.methods
      .withdraw()
      .estimateGas({ from: address })
      .then(gas => {
        smartContract.methods
          .withdraw()
          .send({
            gas,
            to: REACT_APP_CONTRACT_ADDRESS,
            from: address,
          })
          .once('error', err => {
            console.log(err);
            toast.error('It seems the transaction was cancelled.');
            toast.error(err.message);
          })
          .then(receipt => {
            toast.success('Woohoo! withdrawal successfully!');
          });
      })
      .catch(err => {
        if (err.message.includes('insufficient funds')) {
          toast.error('Insufficient Funds');
        } else if (
          err.message.includes('Not a valid leaf in the Merkle tree')
        ) {
          toast.error('Not a whitelist user');
        } else {
          const formatedError = err.message.split('{')[0];
          if (formatedError.includes('execution reverted:')) {
            toast.error(formatedError.split('execution reverted:')[1]);
          } else {
            toast.error(formatedError);
          }
        }
      });
  };

  const forceUpdateMetadata = async () => {
    const mintCount = Array.from(Array(Number(data.maxSupply)).keys());
    const arr = [];
    for await (const num of mintCount) {
      const isMinted = await smartContract.methods
        .checkIfMinted(num + 1)
        .call();
      if (isMinted !== '0x0000000000000000000000000000000000000000') {
        arr.push(num);
      }
    }

    console.log('Minted list', arr);
  };

  const forceUpdate = async () => {
    const mintedIds = [
    ];
    console.log(mintedIds.length);
    for await (const num of mintedIds) {
      const res = await axios.get(
        `https://api.opensea.io/api/v1/asset/0x6E188BcBFc30A461C83Edc70601cb088F560B730/${num}/?force_update=true`
      );
      console.log(res);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        h="100vh"
        backgroundImage={`${process.env.PUBLIC_URL}/1.jpeg`}
        backgroundPosition="center"
        backgroundSize="cover"
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          w="100%"
          bg="rgba(0, 0, 0, 0.5)"
          h="120px"
          position="absolute"
          top="0"
          py="10px"
        >
          <Flex
            justifyContent="space-between"
            maxW="1300px"
            mx="auto"
            alignItems="center"
          >
            <RouteLink to="/">
              <Image src={`${process.env.PUBLIC_URL}/hlogo.png`} w="107px" />
            </RouteLink>
            {!address ? (
              <Flex
                as="button"
                bg="lightOrange"
                width="300px"
                h="55px"
                color="white"
                borderRadius="50px"
                justifyContent="center"
                alignItems="center"
                onClick={connect}
              >
                <Heading textAlign="center" fontSize="20px">
                  Connect Wallet
                </Heading>
              </Flex>
            ) : (
              <Flex
                bg="lightOrange"
                width={{ base: '200px', md: '300px' }}
                h={{ base: '45px', md: '55px' }}
                borderRadius="50px"
                justifyContent="center"
                alignItems="center"
              >
                <Text color="white" fontSize={{ base: 'xl', md: '2xl' }}>
                  {shortenAddress(address || '')}
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
        <Fade left>
          <Box px="10px">
            <Box maxW={{ base: '380px', md: '1200px' }} bg="box" p="20px">
              <Text fontSize="3xl">Do not refresh while on this page</Text>
              <Heading fontSize="75px" color="black" lineHeight="90px">
                MINT BATC
              </Heading>

              <Text
                mb="10px"
                textAlign="justify"
                color="black"
                fontSize="50px"
                w="100%"
              >
                {`${totalSupply}/3000 BATC minted`}
              </Text>

              <Flex>
                <Flex
                  as="button"
                  bg="lightOrange"
                  w="70px"
                  h="70px"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    if (count > 1) {
                      setcount(count - 1);
                    }
                  }}
                >
                  <AiOutlineMinus color="white" size="30" />
                </Flex>
                <Flex
                  w="200px"
                  h="70px"
                  justifyContent="center"
                  alignItems="center"
                  bg="white"
                >
                  <Text fontSize="4xl">{count}</Text>
                </Flex>
                <Flex
                  as="button"
                  bg="lightOrange"
                  w="70px"
                  h="70px"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    if (count < maxAmount) {
                      setcount(count + 1);
                    }
                  }}
                >
                  <AiOutlinePlus color="white" size="30" />
                </Flex>
              </Flex>
            </Box>
            <Box
              as="button"
              bg="lightOrange"
              minW="280px"
              h="60px"
              borderRadius="50px"
              mt="20px"
              disabled={loading || (address && !presaleActive && !saleActive)}
              onClick={() => {
                if (!address) {
                  handleConnect();
                }

                if (address && presaleActive) {
                  whitelistClaimNFTs(count);
                }
                if (address && saleActive) {
                  claimNFTs(count);
                }
              }}
            >
              <Text color="white" fontSize="25px">
                {!address
                  ? 'Connect'
                  : loading
                  ? 'Please wait...'
                  : !presaleActive && !saleActive
                  ? 'Sale not active'
                  : 'Mint'}
              </Text>
            </Box>
            {isOwner() && (
              <Box
                as="button"
                bg="lightOrange"
                minW="280px"
                h="60px"
                ml="30px"
                borderRadius="50px"
                mt="20px"
                onClick={onWithdraw}
              >
                <Text color="white" fontSize="25px">
                  Withdraw
                </Text>
              </Box>
            )}
          </Box>
        </Fade>
      </Flex>
    </>
  );
}

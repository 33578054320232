const initialState = {
  loading: false,
  name: '',
  totalSupply: 0,
  cost: 0.09,
  error: false,
  errorMsg: '',
  maxSupply: 10000,
  maxMintAmount: 3,
  maxMintHigerAmount: 4,
  presaleActive: false,
  saleActive: false,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_DATA_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        name: action.payload.name,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxSupply: action.payload.maxSupply,
        maxMintAmount: action.payload.maxMintAmount,
        maxMintHigerAmount: action.payload.maxMintHigerAmount,
        presaleActive: action.payload.presaleActive,
        saleActive: action.payload.saleActive,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_DATA_FAILED':
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;

import { Box, Flex, Heading, Image, Text, Link } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Zoom from 'react-reveal/Zoom';

const { PUBLIC_URL } = process.env;

const images = new Array(30).fill().map((_, i) => i);

const names = {
  29: 'pcw841',
  30: 'Basicss11',
  31: 'Saint Bayview',
  32: 'BAYC1871.eth',
  33: 'Tiger Vegemite',
};

export default function Gallery() {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 8000,
    pauseOnHover: false,
    cssEase: 'linear',
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1000,
          speed: 8000,
          pauseOnHover: false,
          cssEase: 'linear',
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1000,
          speed: 8000,
          pauseOnHover: false,
          cssEase: 'linear',
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1000,
          speed: 8000,
          pauseOnHover: false,
          cssEase: 'linear',
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Box py="30px" id="gallery">
      <Flex flexDirection="column" alignItems="center" py="30px" id="gallery">
        <Heading
          color="white"
          fontSize={{ base: '34px', lg: '70px' }}
          mb="20px"
          textAlign="center"
        >
          Our Partners
        </Heading>
        <a href="https://tunesaudio.com/" target={'_blank'}>
          <Image
            cursor="pointer"
            // onClick={()=> window.location.href = ''}
            mb="50px"
            maxW={{ base: '100px', md: '223px' }}
            src={`${process.env.PUBLIC_URL}/logo.jpeg`}
          />
        </a>
        <Box bg="rgba(255,255,255,0.4)" w="100%">
          <Box py="50px" maxW="1320px" mx="auto" w="100%">
            <Heading
              color="white"
              fontSize={{ base: '38px', lg: '70px' }}
              mb="20px"
              textAlign="center"
            >
              As Featured On
            </Heading>
            <Flex
              justifyContent="space-around"
              alignItems="center"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Link
                href="https://finance.yahoo.com/news/zmax-mega-nft-project-beyond-030400382.html"
                isExternal
                maxW="200px"
                mb={{ base: '50px', md: 0 }}
              >
                <Image src={`${process.env.PUBLIC_URL}/yahoo.png`} />
              </Link>
              <Link
                href="https://bloomberg.com/press-releases/2022-03-29/zmax-the-revival-of-picture-for-proof-nfts"
                isExternal
                maxW="200px"
                mb={{ base: '50px', md: 0 }}
              >
                <Image src={`${process.env.PUBLIC_URL}/bloomberg.png`} />
              </Link>
              <Link
                href="https://businesswire.com/news/home/20220329005695/en/ZMAX-%E2%80%93-The-Revival-of-Picture-for-Proof-NFTs"
                isExternal
                maxW="200px"
                mb={{ base: '50px', md: 0 }}
              >
                <Image src={`${process.env.PUBLIC_URL}/business.png`} />
              </Link>
              <Link
                href="https://wvnews.com/news/around_the_web/zmax-the-revival-of-picture-for-proof-nfts/article_e4e774af-9c3d-5ae4-8ac6-d569048bb106.html"
                isExternal
                maxW="200px"
                mb={{ base: '50px', md: 0 }}
              >
                <Image src={`${process.env.PUBLIC_URL}/wvnews.png`} />
              </Link>
            </Flex>
          </Box>
        </Box>
        <Zoom>
          <Heading
            mb="20px"
            mt="50px"
            fontSize={{ base: '34px', lg: '70px' }}
            textAlign="center"
            color="white"
          >
            Our BAYC & MAYC Supporters
          </Heading>
        </Zoom>
      </Flex>
      <Zoom>
        <Slider {...settings}>
          {images.map(img => (
            <Box w="300px" ml="10px" className="img-container">
              <Image key={img} src={`${PUBLIC_URL}/${img + 1}.png`} />

              <Flex className="img-overlay">
                {names[img + 1] && (
                  <Text color="white" fontSize="3xl">
                    {names[img + 1]}
                  </Text>
                )}
              </Flex>
            </Box>
          ))}
        </Slider>
      </Zoom>
    </Box>
  );
}

import React from 'react';
import { Box, Flex, Text, Heading, Link, Image } from '@chakra-ui/react';
import Fade from 'react-reveal/Fade';
import { Link as RouteLink } from 'react-router-dom';

import { Header } from '../components';

export default function Home({ onConnect, address }) {
  return (
    <>
      <Header onConnect={onConnect} address={address} />

      <Flex
        w="100%"
        h="100vh"
        backgroundImage={`${process.env.PUBLIC_URL}/1.jpeg`}
        backgroundPosition="center"
        backgroundSize="cover"
        flexDirection="column"
        position="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Fade left>
          <Box px="10px" display={{ base: 'block', lg: 'none' }}>
            <Box maxW="380px" bg="box">
              <Heading fontSize="75px" color="black" lineHeight="90px">
                Welcome to The Urban!
              </Heading>

              <Text
                mb="10px"
                textAlign="justify"
                color="black"
                fontSize="22px"
                w="100%"
              >
                This is where you will find the coolest Bored Ape Teens! Never
                miss another exciting moment, connect with us on Discord,
                Twitter, and IG now.
              </Text>

              <Text color="black" fontSize="22px" w="100%">
                Let’s get Ape-solutely crazy together!
              </Text>
            </Box>
            <Flex flexDirection="column" alignItems="center">
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <Link href="https://discord.com/invite/BQVfzsAKHJ" isExternal>
                  <Text color="white" fontSize="25px">
                    Join Us
                  </Text>
                </Link>
              </Box>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <RouteLink to="mint">
                  <Text color="white" fontSize="25px">
                    Mint Now
                  </Text>
                </RouteLink>
              </Box>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <Link
                  href="https://opensea.io/collection/bored-ape-teen-club-official"
                  display="flex"
                  justifyContent="center"
                  isExternal
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/opensea.png`}
                    w="30px"
                    mr="10px"
                  />
                  <Text color="white" fontSize="18px">
                    OpenSea Official
                  </Text>
                </Link>
              </Box>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <Link
                  href="https://looksrare.org/collections/0x6E188BcBFc30A461C83Edc70601cb088F560B730"
                  display="flex"
                  justifyContent="center"
                  isExternal
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/lookrare.png`}
                    w="30px"
                    mr="10px"
                  />
                  <Text color="white" fontSize="18px">
                    LooksRare Official
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Fade>

        <Box
          position="absolute"
          top="10"
          left="55%"
          display={{ base: 'none', lg: 'block' }}
        >
          <Fade left>
            <Box maxW="520px" bg="box">
              <Heading fontSize="75px" color="black" lineHeight="90px">
                Welcome to The Urban!
              </Heading>

              <Text
                mb="10px"
                textAlign="justify"
                color="black"
                fontSize="22px"
                w="470px"
              >
                This is where you will find the coolest Bored Ape Teens! Never
                miss another exciting moment, connect with us on Discord,
                Twitter, and IG now.
              </Text>

              <Text color="black" fontSize="22px" w="470px">
                Let’s get Ape-solutely crazy together!
              </Text>
            </Box>
          </Fade>
          <Fade left>
            <Flex>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <Link href="https://discord.com/invite/BQVfzsAKHJ" isExternal>
                  <Text color="white" fontSize="28px">
                    Join Us
                  </Text>
                </Link>
              </Box>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
                ml="20px"
              >
                <RouteLink to="mint">
                  <Text color="white" fontSize="28px">
                    Mint Now
                  </Text>
                </RouteLink>
              </Box>
            </Flex>
            <Flex>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
              >
                <Link
                  href="https://opensea.io/collection/bored-ape-teen-club-official"
                  display="flex"
                  justifyContent="center"
                  isExternal
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/opensea.png`}
                    w="30px"
                    mr="10px"
                  />
                  <Text color="white" fontSize="18px">
                    OpenSea Official
                  </Text>
                </Link>
              </Box>
              <Box
                as="button"
                bg="lightOrange"
                w="250px"
                h="57px"
                borderRadius="50px"
                mt="20px"
                ml="20px"
              >
                <Link
                  href="https://looksrare.org/collections/0x6E188BcBFc30A461C83Edc70601cb088F560B730"
                  display="flex"
                  justifyContent="center"
                  isExternal
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/lookrare.png`}
                    w="30px"
                    mr="10px"
                  />
                  <Text color="white" fontSize="18px">
                    LooksRare Official
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Fade>
        </Box>
      </Flex>
    </>
  );
}

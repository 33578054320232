const initialState = {
  loading: false,
  provider: null,
  address: null,
  chainId: null,
  smartContract: null,
  web3Provider: null,
  data: {
    totalSupply: 0,
    cost: 0.09,
    maxSupply: 10000,
    maxMintAmount: 3,
    maxMintHigerAmount: 4,
    presaleActive: false,
    saleActive: false,
    owner: null,
  },
};

 const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
        smartContract: action.smartContract,
        loading: false,
      };
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
        loading: false,
      };
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: action.chainId,
        loading: false,
      };
    case 'SET_DATA':
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'RESET_WEB3_PROVIDER':
      return initialState;
    default:
      return initialState;
  }
 }

 export default walletReducer;
import { Flex, Box, Text, Heading } from '@chakra-ui/react';
import TeamCard from '../components/TeamCard';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
export default function About() {
  return (
    <Box maxW="1320px" margin={'auto'} mx="auto" id="team">
      <Box className='apes_heading' mb="100px" margin={'auto'}>
        <Fade left>
          <Heading color="about" fontSize="70px" fontWeight="bold" mt="50px">
            Our Team
          </Heading>
        </Fade>
        <Fade left delay={200}>
          <Heading mb="30px" color="white" fontSize="50px">
            Apes At work
          </Heading>
        </Fade>
        <Fade left delay={300}>
          <Text mb="100px" mt="40px" color="white" fontSize="18px">
            For special enquiries, please drop us an email at:
            contactus@boredapeteenclub.com
          </Text>
        </Fade>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        py="10px"
        px={{ base: '20px', md: 0 }}
      >
        <Flex
          justifyContent="flex-start"
          flexDirection="column"
          alignItems="center"
        >
          <Fade left>
            <Flex
              justifyContent={{ base: 'center', md: 'space-between' }}
              alignItems={{ base: 'center', md: 'stretch' }}
              flexWrap="wrap"
              w="100%"
            >
              <TeamCard
                name="TRXBOY"
                image="trx.png"
                position="Founder MAKC & BATC, Digital Creator"
                text="Dares the world to dream, and that is how this Bored Ape Teen rolls."
              />
              <TeamCard
                name="SIGHCLOGS"
                image="sing.png"
                position="Co-Founder BATC, Project Director"
                text="Loves swinging around the jungle but cannot see much without his Bored Ape Teen glasses."
              />
              <TeamCard
                name="CALI SUE"
                image="cali.jpg"
                position="Community Manager"
                text={<>This Bored Ape Teen is an avid self-learner who just can't get enough of all that the world around her has to offer.
                <br/>
                She is a Crypto and NFT enthusiast who is focused on creating generational wealth for her loved ones.</>}
              />
                {/* <TeamCard
                name="DEFI DAN"
                image="defi.jpg"
                position="Community Educator"
                text="Teaching is something that this Bored Ape Teen has been doing his entire career. As the Prime Ape of his own private community, Defi Dan brings with him experience and wisdom that can help any Ape get through the wildest of Bull and Bear rides."
              />
              <TeamCard
                name="SNYXZ"
                image="snyxz.png"
                position="Discord Administrator"
                text="Maybe the most evolved Bored Ape Teen on the team, seems to have an answer for everything Discord-related. Phew."
              />
              <TeamCard
                name="KWAYZEE"
                text="Last seen apeing around BATC, MAKC, and MAC. This Bored Ape Teen is a true blue NFT enthusiast who hangs only with the best."
                image="kway.png"
                position="Discord Administrator"
              />

              <TeamCard
                name="CALVINTKH"
                image="calvin.png"
                position="Discord Administrator"
                text="This Bored Ape Teen is on a whole other level when it comes to grinding. Fully dedicated to the cause, he leaves no stone unturned, and no banana unpeeled. "
              />
              <TeamCard
                name="WAFFLEBOT"
                image="wafle.png"
                position="Discord Administrator"
                text="Always in the hunt whether or not this Bored Ape Teen sees Crypto Bulls or Crypto Bears around him. Respect the hustle."
              />
              <TeamCard
                name="JUSS94"
                image="juss.jpeg"
                position="Discord Administrator"
                text="When this Bored Ape Teen is not digging for gold, he is often digging for the next big NFT; which explains how he found us."
              />
              <TeamCard
                name="PTONLINE"
                image="ptonline.jpeg"
                position="Discord Administrator"
                text="Doesn’t enjoy his photos being taken, but loves collecting NFTs. That’s probably because this Bored Ape Teen aped into great company and never stopped expanding his circles since."
              />
              <TeamCard
                name="SWOLEDEMEME"
                image="swolede.png"
                position="Discord Administrator"
                text="Climbing up and down, swinging left to right. This Bored Ape Teen is always keeping himself busy with something."
              /> */}
              
              <TeamCard
                name=""
                image=""
                position=""
                text=""
                style='hidden'
              />
              
            </Flex>
          </Fade>
        </Flex>
        <Zoom>
          <Heading
            mt="100px"
            mb="40px"
            color="white"
            fontSize={{ base: '20px', lg: '50px' }}
          >
            contactus@boredapeteenclub.com
          </Heading>
          <Text textAlign="center" color="white" fontSize="18px">
            What are you waiting for?
          </Text>
        </Zoom>
      </Flex>
    </Box>
  );
}

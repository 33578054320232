export function getRandomNumber(maxSupply) {
  return Math.floor(Math.random() * (maxSupply - 1 + 1) + 1);
}

export async function getRandomNumberArrs(arr, maxSupply, checkIfMined) {
  const randomNum = getRandomNumber(maxSupply);
  const isNotMinted = await checkIfMined(randomNum).call();
  if (isNotMinted === '0x0000000000000000000000000000000000000000') {
    if (!arr.includes(randomNum)) {
      return randomNum;
    }
    return await getRandomNumberArrs(arr, maxSupply, checkIfMined);
  }
  return await getRandomNumberArrs(arr, maxSupply, checkIfMined);
}

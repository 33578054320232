import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Link,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  Heading,
} from '@chakra-ui/react';
import { HiMenu } from 'react-icons/hi';

import { shortenAddress } from '../candy-machine';

export default function Header({ address, onConnect }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = React.useState('right');

  return (
    <>
      <Box p="10px">
        <Flex
          maxW="1320px"
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link href="/" className="link">
            <Image src={`${process.env.PUBLIC_URL}/hlogo.png`} w="107px" />
          </Link>

          <Flex
            ml="200px"
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
          >
            <Link href="/" mr="20px" color="#FFAE01" className="link">
              <Text fontSize="22px"> Home</Text>
            </Link>
            <Link href="#about" mr="20px" color="white" className="link">
              <Text fontSize="22px">About Us</Text>
            </Link>
            <Link href="#feature" mr="20px" color="white" className="link">
              <Text fontSize="22px">Features</Text>
            </Link>
            <Link href="#roadmap" mr="20px" color="white" className="link">
              <Text fontSize="22px">Roadmap</Text>
            </Link>

            <Link href="#team" color="white" mr="20px" className="link">
              <Text fontSize="22px">Our Team</Text>
            </Link>
            {!address ? (
              <Flex
                as="button"
                bg="lightOrange"
                width="300px"
                h="55px"
                color="white"
                borderRadius="50px"
                justifyContent="center"
                alignItems="center"
                className="desktop-btn"
                display={{ base: 'none', lg: 'flex' }}
                onClick={onConnect}
              >
                <Heading textAlign="center" fontSize="20px">
                  Connect Wallet
                </Heading>
              </Flex>
            ) : (
              <Flex
                bg="lightOrange"
                width="300px"
                h="55px"
                borderRadius="50px"
                  display={{ base: 'none', lg: 'flex' }}
                  justifyContent="center"
                  alignItems="center"
              >
                <Text color="white" fontSize="2xl">{shortenAddress(address || '')}</Text>
              </Flex>
            )}
          </Flex>

          <Box
            as="button"
            bg="white"
            p="2"
            onClick={onOpen}
            display={{ base: 'block', md: 'none' }}
          >
            <HiMenu color="black" size="30" />
          </Box>
        </Flex>
      </Box>
      <Drawer
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
        display={{ base: 'block', md: 'none' }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody bg="black">
            <Link
              href="#about"
              className="link"
              display="block"
              color="white"
              fontSize="18px"
              py="15px"
            >
              About
            </Link>
            <Link
              href="#roadmap"
              className="link"
              display="block"
              color="white"
              fontSize="18px"
              py="15px"
            >
              Roadmap
            </Link>
            <Link
              href="#gallery"
              className="link"
              display="block"
              color="white"
              fontSize="18px"
              py="15px"
            >
              Gallery
            </Link>

            <Link
              href="#team"
              display="block"
              className="link"
              color="white"
              fontSize="18px"
              py="15px"
            >
              Team
            </Link>

            {!address ? (
              <Flex
                as="button"
                bg="lightOrange"
                width="200px"
                h="55px"
                color="white"
                borderRadius="50px"
                justifyContent="center"
                alignItems="center"
                onClick={onConnect}
              >
                <Heading textAlign="center" fontSize="16px">
                  Connect Wallet
                </Heading>
              </Flex>
            ) : (
              <Box bg="lightOrange" px="20px" py="10px" borderRadius="5px">
                <Text color="white">{shortenAddress(address || '')}</Text>
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
